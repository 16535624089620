define("lunchcoin/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "op7p9Bzd",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Lunchcoin\"],null]],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\\n\"],[10,\"footer\"],[14,\"aria-labelledby\",\"footerHeading\"],[12],[2,\"\\n    \"],[10,\"h2\"],[14,1,\"footerHeading\"],[14,0,\"sr-only\"],[12],[2,\"Footer\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"max-w-2xl mx-auto py-12\"],[12],[2,\"\\n        \"],[10,\"div\"],[12],[2,\"\\n            \"],[10,\"a\"],[14,6,\"https://github.com/lunchcoin\"],[14,0,\"flex items-center text-gray-400 hover:text-gray-300\"],[12],[2,\"\\n                \"],[10,\"span\"],[14,0,\"sr-only\"],[12],[2,\"GitHub\"],[13],[2,\"\\n                \"],[10,\"svg\"],[14,0,\"h-6 w-6\"],[14,\"fill\",\"currentColor\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"aria-hidden\",\"true\"],[12],[2,\"\\n                    \"],[10,\"path\"],[14,\"fill-rule\",\"evenodd\"],[14,\"d\",\"M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z\"],[14,\"clip-rule\",\"evenodd\"],[12],[13],[2,\"\\n                \"],[13],[2,\"\\n                \"],[10,\"span\"],[14,0,\"ml-3\"],[12],[2,\"Follow development on Github\"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"page-title\",\"-outlet\",\"component\"]}",
    "moduleName": "lunchcoin/templates/application.hbs"
  });

  _exports.default = _default;
});